"use strict";

// TODO make Tree js its own package.

import * as Coze from '../pkg/coze_all~fv=-7uCIHNa.min.js'
import * as BaseHex64 from './base_hex64.js'

export {
	BundleTypes,

	Identity,
	PopulateTree,
	PopulateBundleIDs,
	GenTreeBranch,
	IntToBytesBE,
};

/**
@typedef {import('../../../pkg/cozejs/typedef.js').Alg} Alg
@typedef {import('../../../pkg/cozejs/typedef.js').Hsh} Hsh
@typedef {import('../../../pkg/cozejs/typedef.js').B64} B64

@typedef {number[]}     BLS // brach_level_sizes
*/


/**
 Tree represents a lightning/staircase tree.  The tree has a (secret) trunk
 chain and (public) branches.  The hashing chain is the "trunk", the seed plus
 the trunk digest is a branch.  This datastructure may be used recursively, and
 a branch of tree size 0 is a leaf.

alg: The coze.Hsh (Hash alg) for digests. e.g. "SHA-256"

seed: "Private" seed. Seed is the starting digest for tree. Seed length must be
same length as HashSize as digest. Should be same length. e.g.
RpMM4_lU6jCj3asZEtIFyYqPjC2L6mlucl7VGMvAuno

id: The B64 "Public" seed identifier.

skip: Optional starting position.  Populates tree starting at this position.

Branches: The digest values of the branches off the main trunk chain. Branch
value become the seed for the children level. digest(seed||digest(Seed||byte 0))
= BD1 where "0" is the integer value in bytes of the current branch level.

pathCalc: If true, Paths, Leaves, and IDPaths are calculated.

paths: ("Private") Seed paths. The tree path (all branches above) for all nodes.
All leaves in a branch share the same Path. Both keys and values are seeds.
Object of keys as string, values as B64[] of path, e.g.  "paths": {"77Jo...":
["0RJw..."],

pathsID: ("Public") Paths except with ID's for both keys and values.

leaves: ("Private") A slice of leaves in the tree down.  Only calculated if
LeafPathCalc. Leaves are "private" like seed and branch.  Use Identity for
"public" ID.

leavesID: ("Public") Like Leaves except uses ID's as the keys and values.

TotalLeaves: Number of leaves currently populated in the whole tree, including
"skip". (Children trees `TreeTotalLeaves` is parent tree's
`TreeTotalLeaves` as well.) Leaves are the last branch with no children.- 

Children: Recursive tree.  Each digest in "Branch" is the key for the tree in
"BranchTree" on a one to one basis.  Note that the "public" value for the branch
is used for the generation of a child tree and not the "secret" value from the
trunk chain.
@typedef  {object}    Tree
@property {Hsh}       alg
@property {B64}       seed
@property {B64}       id
@property {BLS}       branch_level_sizes
@property {B64[]}     [branches]

@property {number}    [skip]

@property {boolean}   [pathCalc]
@property {B64MapP}   [paths]
@property {B64MapP}   [pathsID]
@property {B64[]}     [leaves]
@property {B64[]}     [leavesID]

// Stats and internal variables.  Should not be printed.  
@property {number}    [total_leaves]   // For whole tree, up and down.
@property {number}    [max_total_leaves] // For whole tree, up and down. // TODO
not implemented

@property {Tree[]}    [children]  // Children appear last for readability.
*/

/**
B64Map is for mimicking the map `B64MapP` in the GO tree package. B64 IDs are
used as keys and values are an array of B64 IDs.
@typedef  {object}  B64MapP
@property {B64}
@property {B64[]}
*/

/**
Identity produces the ID from seed.
@param   {CozeAlg}   alg     Hashing Alg e.g. SHA-256
@param   {B64}       seed    Starting seed.  Should be same length as  e.g. RpMM4_lU6jCj3asZEtIFyYqPjC2L6mlucl7VGMvAuno
@returns {B64}       ID
**/
async function Identity(alg, seed) {
	// console.log("Identity", alg, seed);
	if (typeof seed !== "string") {
		throw new Error(`Tree.Identity: input seed must be of type string (b64ut); given type: ${typeof seed}`) // TODO consider consistent error formatting.  
	}
	let seedB = await BaseHex64.B64utToArrayBuffer(seed)
	if (seedB.byteLength !== Coze.HashSize(alg)) {
		throw new Error("Tree.Identity: seed size does not match alg's hash size; ")
	}
	//console.debug("Alg: ", Coze.HashSize(tree.alg), "; Seed: ", tree.seedB.byteLength)
	return BaseHex64.ArrayBufferTo64ut(await crypto.subtle.digest(alg, seedB))
}

/**
CalcPathsID converts Tree.Paths from "seed"/"private" form to "id"/"public" form
and sets Tree.IDPaths.
@param   {Tree} tree
@returns {void}
@throws  {error}
 */
async function CalcPathsID(tree) {
	if (!tree.pathCalc) {
		return
	}
	tree.pathsID = {}
	for (let key in tree.paths) {
		var pathsID = []
		for (let id in tree.paths[key]) {
			pathsID.push(await Identity(tree.alg, tree.paths[key][id])) // throws
		}
		tree.pathsID[await Identity(tree.alg, key)] = pathsID // throws
	}
}


/**
PopulateTree recursively populates tree using givens alg, seed, and
branch_level_sizes. 
PopulateTree Does not populate current branch so to populate current branch,
example a branch_level_sizes of [2], put an implicit 1 in front, [1, 2].
@param   {Tree} tree
@returns {void}
 */
async function PopulateTree(tree) {
	tree.id = await Identity(tree.alg, tree.seed)
	if (isEmpty(tree.total_leaves)) {
		tree.total_leaves = 0
	}
	if (tree.pathCalc) {
		tree.leaves = []
		tree.leavesID = []
	}

	await GenTreeBranch(tree)
	if (!isEmpty(tree.branches) && tree.branch_level_sizes.length > 1) { // Children
		tree.children = []
		for (let i = 0; i < tree.branches.length; i++) {
			let t = {
				alg: tree.alg,
				seed: tree.branches[i],
				branch_level_sizes: tree.branch_level_sizes.slice(1, tree.branch_level_sizes.length),
				max_total_leaves: tree.max_total_leaves,
				total_leaves: tree.total_leaves,
				pathCalc: tree.pathCalc
			}
			await PopulateTree(t)
			tree.children[i] = t

			if (tree.pathCalc) {
				tree.leaves = tree.leaves.concat(t.leaves) // Add leaves from branch to parent's leaves.
				for (let key in t.paths) {
					tree.paths[key] = [tree.seed].concat(t.paths[key]) // Add parent to each child's path.
				}
			}
		}
	}

	if (tree.pathCalc) {
		tree.leavesID = []
		for (let leaf of tree.leaves) {
			tree.leavesID.push(await Identity(tree.alg, leaf))
		}
	}

	// Since Javascript does not have the Go equivalent to json ignore, we simply
	// delete "internal statistic vars" before returning the tree.  Otherwise, a
	// string printing method should be defined on tree. 
	delete tree.total_leaves

	await CalcPathsID(tree)
}


/**
GenTreeBranch generates a slice of digests from a seed digest.
Resulting digests are not cryptographically related.

Skip is the starting position for the main trunk (should typically be 0).
// TODO skip needs to be tested/match Go implementation.

S─(S)─► ID
│
├─(S+0)──► D1
│
├─(S+1)──► D2
│
├─(S+2)──► D3
...

@param   {Tree}  tree
@returns {void}
 */
async function GenTreeBranch(tree) {
	let seedB = await BaseHex64.B64utToArrayBuffer(tree.seed)
	if (seedB.byteLength !== Coze.HashSize(tree.alg)) {
		throw new Error(`Tree.GenTreeBranch: seed length does not match hash alg length; seed length ${seedB.byteLength}; hash length ${Coze.HashSize(tree.alg)}`)
	}

	let skip = isEmpty(tree.skip) ? 0 : tree.skip

	if (tree.pathCalc) {
		tree.paths = {}
	}

	for (let i = 0; i < tree.branch_level_sizes[0] - skip; i++) {
		if (isEmpty(tree.branches)) {
			tree.branches = []
		}
		if (tree.total_leaves == tree.max_total_leaves) {
			return
		}

		// Calculate nonce bytes and merge seed and nonce array buffers.
		let nonce = IntToBytesBE(i + skip)
		let s = new Uint8Array(seedB.byteLength + nonce.byteLength)
		s.set(new Uint8Array(seedB), 0)
		s.set(new Uint8Array(nonce), seedB.byteLength)
		tree.branches[i] = await BaseHex64.ArrayBufferTo64ut(await crypto.subtle.digest(tree.alg, s.buffer))

		if (tree.branch_level_sizes.length == 1) { // Leaves
			// console.log("Edge of tree");
			tree.total_leaves++
			if (tree.pathCalc) {
				tree.leaves = tree.leaves.concat(tree.branches[i])
			}
		}
		if (tree.pathCalc) {
			tree.paths[tree.branches[i]] = [tree.seed]
		}
	}
	return
}


/**
IntToBytesBE converts an integer to bytes does not include empty padding bytes
and always returns at least one byte. (Empty padding bytes on left, e.g. decimal
65,536 is bytes [1 0 0].) As currently written, it only supports numbers up to
2^32 (4294967295) which is the max value for uint32. We did not investigate why
Javascript has a 4 byte limit on integers. See the Go cyphrme/tree
implementation.
@param   {number}  int
@returns {void}
 */
function IntToBytesBE(int) {
	if (int > 4294967295) {
		throw new Error(`Tree.IntToBytesBE: cannot convert number larger than 4294967295`)
	}
	let byteLength = Math.ceil(Math.log2(int + 1) / 8);
	byteLength = Math.max(byteLength, 1); // Ensure minimum byte length of 1

	const byteArray = new Uint8Array(byteLength);
	for (let i = byteLength - 1; i >= 0; i--) {
		byteArray[i] = int & 0xFF;
		int >>= 8;
	}

	return byteArray;


	// Two alternative, non-working implementation (still appears to have the 2^32
	// limitation, and they return padding bytes which would need to be removed. 
	// //function IntToBytesBE(number) {
	// // 	const byteArray = new Uint8Array(8); // Assuming a 64-bit integer
	// // 		//0,8,16,24,32,40,48,56  
	// // 	byteArray[0] = (number >> 56) & 0xFF;
	// // 	byteArray[1] = (number >> 48) & 0xFF;
	// // 	byteArray[2] = (number >> 40) & 0xFF;
	// // 	byteArray[3] = (number >> 32) & 0xFF;
	// // 	byteArray[4] = (number >> 24) & 0xFF;
	// // 	byteArray[5] = (number >> 16) & 0xFF;
	// // 	byteArray[6] = (number >> 8) & 0xFF;
	// // 	byteArray[7] = number & 0xFF;
	// // 	return byteArray;
	// // }

	// 	// function IntToBytesBE2(number) {
	// //   const byteArray = new Uint8Array(4); // Assuming a 64-bit integer
	// //   byteArray[0] = (number >> 24) & 0xFF;
	// //   byteArray[1] = (number >> 16) & 0xFF;
	// //   byteArray[2] = (number >> 8) & 0xFF;
	// //   byteArray[3] = number & 0xFF;
	// //   return byteArray;
	// // }

}

////////////////////////////////////////////////////////////////////////////////
// Internal to Cyphrme and outside of Tree package
////////////////////////////////////////////////////////////////////////////////

const BundleTypes = ["item", "page", "batch", "box", "pallet", "rack", "warehouse"]

/**
PopulateBundleIDs populates BundlePaths on the given ACs from IDPaths on Tree.
@param   {Tree}  tree
@returns {void}
 */
function PopulateBundleIDs(tree, acs) {
	for (let i in acs) {
		if (!(acs[i].pay.id in tree.pathsID)) {
			throw new Error("id not in map")
		}
		let paths = tree.pathsID[acs[i].pay.id]
		let bp = {}
		for (let i in paths) {
			if (i == 0) {
				bp.tree = paths[i]
				continue
			}
			switch (BundleTypes[paths.length - i]) {
				default:
					throw new Error("bundle type not defined")
				case "page":
					bp.page = paths[i]
					break
				case "batch":
					bp.batch = paths[i]
					break
				case "box":
					bp.box = paths[i]
					break
				case "pallet":
					bp.pallet = paths[i]
					break
				case "rack":
					bp.rack = paths[i]
					break
				case "warehouse":
					bp.warehouse = paths[i]
					break
			}
		}
		acs[i].bundle_paths = bp
	}
}